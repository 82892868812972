$rem:1rem;
$huge:26.25rem;
$tall:21.25rem;
$medium:10rem;
$short:2.8rem;
$single:10%;
$full:100%;
$qtr:$rem * .25;//5px
$hlf:$rem * .5;
$fxmp:flex !important;

$mp:!important;

.tall{height:$tall;}
.medium{height:$medium;}
.short{height:$short;}
.full{height:$full;}
.huge{height:$huge;}

.moduletable.inline{display:inline-flex;}

div.canvas_wrapper{
  display:flex;
  width:100% $mp;
  flex: 0 0 auto;
  /*height:100% $mp;*/
  /*margin:.5rem auto;*/
  .canvas_cont{
    display:table-cell $mp;
    /*width:unset $mp;*/
    width:100%;
    height:100%;
    .megaMenu.canvas_area{
      margin:0 auto;
      width:unset $mp;
      height:unset $mp;
      display:unset $mp;
      .megaMenu.ImgCanvas{width:100% $mp; height:auto $mp;}
    }
  }
}


.mega-menu{
  /*display: flex;  // flex right here is creating a problem with another section borders overrun*/
  justify-content: center;
  margin: 0 auto;
  .fixed{position:fixed $mp;}
  .d3_range_stack{
    width:100%;
    .mM_stgs_label_cont{
      display:flex;
      width:100%;
      .d3_auto_range{
        display:flex; flex-flow:column; align-items: center;
        label{text-align:center;}
      }
    }
    .word_box{ input{max-width:90px;}}
  }
  .showTime_mega_menu{
     max-width:unset;/*flexbox clashes with w3CSS? breaks the display*/
     .megaMenu_nav{
       display:flex; align-items: center; flex-flow:row nowrap;
       .megaMenu_nav_btn{
         white-space: nowrap; overflow:hidden; text-overflow: ellipsis;
         *{color:inherit; text-shadow: none;}
       }
       .megaMenu_nav_btn.canvas, .megaMenu_nav_btn.mM_logo{ display:flex; justify-content: center; align-items: center;}
       .megaMenu_nav_btn.text{display:flex;align-items: center;}
       .megaMenu_nav_icon{  display: flex; justify-content: center; align-items:
        center;padding:unset; height: 100%; width:100%;}
        .megaMenu_nav_icon::after{height:100%;}
     }
    .megaMenu_menu_cont{
      width:100%; position:relative;
      .megaMenu_main{
        display:flex;justify-content: center; max-width:unset;flex-flow:row wrap;
        .megaMenu_item{
          display: inline-flex;
          flex-flow: column;
          /*width:30% $mp;*/
          /*height:100% $mp;*/
          background-color:#fff;
          margin:$rem * .25;
          padding:10px;
          .read_more_cont{
            display:inline-flex; align-items:flex-end; flex: 1; width: 100%; flex-flow: column;
            .read_more{cursor:pointer; white-space: nowrap; overflow:hidden; text-overflow: ellipsis; }
            .button {text-decoration: none;}
          }
        }
        .megaMenu_head_html{
          text-shadow: none $mp;
          p{margin:0px;}
          .head_date{font-size:.1rem}
        }
        .megaMenu_head_html.master,.megaMenu_body_html.master{
          p{ display: flex; align-items: center; margin: 0 0 10px;}
          *{font-size:inherit $mp; line-height: inherit $mp; font-family: inherit $mp;
          color: inherit $mp; text-decoration: inherit $mp;}
        }
        .megaMenu_body_html{
          ul{margin:unset $mp; padding:unset $mp;}
          p{margin:0px;}
        }
        a.megaMenu_head_html{cursor:pointer;}
        button{
          padding:unset $mp;
          border: none;
          display: inline-block;
          outline: 0;
          padding: 8px 16px;
          vertical-align: middle;
          overflow: hidden;
          text-decoration: none;
          color: inherit;
          background-color: inherit;
          text-align: center;
          cursor: pointer;
          white-space: nowrap;
        }
        button.w3-button:hover{background-color:unset $mp;}
      }/*megaMenu_main*/
    }/* megaMenu_menu_cont */
  }
  .flex_fill{flex:1 0 auto $mp;}
  .mM_stgs {
      flex:1;
      width:100%;
      .mM_stgs_ctrls_cont{
        display:flex; flex-flow:row nowrap;
        .mM_stgs_ctrls{flex:1; border:1px solid blue; padding:1px $mp;}
        .mM_stgs_ctrls.first{margin-right:2px;}
      }
      .mM_stgs_sect_cont{max-height:$rem * 20; overflow-y:auto; margin-top:10px;border: 1px solid #ddd; position:relative;}
      .active{background-color:#17315a; color:white;}
      .mM_stgs_advanced{
        position:relative;
        .mM_stgs_option_back{position:absolute; top:5px; right:5px;padding:unset;}
        label.heading{/*margin:3px 10px;*/ margin: unset; height: 25px; padding: 5px 10px; width: 100%; z-index: 1000;}
        .option_section{
          display:flex;
          flex-flow:column nowrap;
          margin-top:5px;
          min-height: 80px;
          .adv_option_btn:first-child{border:1px solid #ccc;}
          .adv_option_btn{
            display:flex; flex-flow:row;
            justify-content: space-between;
            align-items: center;
            flex:1; padding:2px 5px;
            border:1px solid #ccc; height:30px;
            text-align:left $mp;
            border-top:unset;
            p{width:70%; margin:unset;}
            .list_icon{width:10%; display: flex; justify-content: center; align-items: center; }
          }
        }
      }
      textarea {min-height: 15rem $mp;}
      textarea[class*="_stgs_advanced_class"],
      textarea[class*="custom_class"],
      textarea .custom_class{min-height: 5rem $mp;}
  }/*_stgs*/
  .mM_stgs_sect{display:flex; flex-flow:column; width:100%;}
}
.mM_stgs_label{text-shadow: 2px 2px 2px #ccc; font-size:$rem * .95;}
.mM_stgs_content_box{
  border: 1px solid #ddd; padding:10px 10px 15px;
  label{width:100%;}
  label.shared{width:60%;}
  .measure_btns{ width:100%;margin:15px auto 5px;}
}
.info_space{position:relative;}
.mM_stgs_current_info{
  display:flex;
  flex-flow:wrap row;
  div{flex:1;}
}
.mM_stgs_custom_info{
  display:flex; flex-flow:wrap;
  .mM_stgs_size_wrapr{
    div{width:30%; display:inline-block;}
    .mM_stgs_custom_input{width:40% $mp; display:inline-block; padding: 10px;
     margin: 0px 0 10px  2%; vertical-align: top;}
  }
}
.mM_stgs_mobility_info,.mM_stgs_button_info{
  display:flex; flex-flow:wrap;
  .mM_switch_cont{
    width:100%;
    .mM_switch_label{width:unset; margin-right:7%;}
    .fs_slider{min-width: 54px;}
  }


  button{flex:1; border:1px solid blue; padding:1px $mp; margin-right:2px; padding-top:5px $mp; border-radius:0;}
  button.last{ border-radius: 0 5px 5px 0; margin-right:unset;}
  button.first{border-radius: 5px 0 0 5px; }
  button.justify.active{
    /*background-color: #17315a6b;*/
    .material-icons{color:#fff $mp;}
  }
}
.mM_stgs_bg_info{
  display:flex;
  flex-flow:row wrap;
  input{flex:1; padding:0 10px $mp; width:100%;}
}
.mM_stgs_bg_info.col{
  flex-flow:column nowrap;
  button.custom_delete{background-color: rgba(232, 169, 169, 0.44); border-radius: 5px;}
}
.mM_stgs_class_info{
  display:flex;
  flex-flow:row wrap;
  input{flex:1; width:100%; padding:10px;}
}
.mM_stgs_reset_btn{width:90%; margin:10px auto; border:1px solid blue; padding:unset $mp;}
.mM_font_size,.mM_line_height{
  display:flex; flex-flow:column;

  .word_box{
    display:flex;
    flex-flow: row nowrap;
    width:50% $mp;
    input{padding:10px $mp; width:40% $mp; margin-right: 5px;}
    label{width:8%;}
  }
  .font_measure_btns{ width:100%;margin:5px auto 15px;}
}

/*items section*/
.mM_item_label_cont.sticky{ position: -webkit-sticky; position: sticky; top: 0px; background:#fff;}
.mM_item_label_cont{
  padding:5px 10px; display:flex;flex-flow:row nowrap;justify-content: space-between;
  z-index:50;
  .mM_item_label_text{
    display:flex;flex-flow:column; width:80%; text-align: left;
    h4{margin:unset;    font-size: $rem * .89;}
    small{white-space: nowrap; overflow:hidden; text-overflow: ellipsis; }
  }
  .mM_item_label_text.select{width:100%;}
  .mM_item_label_icon{padding:5px; width:30px; height:30px;}
}
.mM_hr{margin:unset;}
.mM_new_item_cont{
  width:100%;
  padding:5px 10px;
  position: -webkit-sticky; position: sticky; bottom: 0px; background:#fff;
  .mM_new_item_title_editor{
    padding:5px;
    display:flex;
    flex-flow:column;
    div{
      display:flex; flex-flow:row nowrap; width:100%; align-items: center;margin-bottom: 5px;
      label{width:70%;margin:unset;}
      button{width:20%; padding:unset $mp; display: flex; justify-content: center; height:25px;}
    }
    input{width:100%;height:2rem;}
    input.invalid:focus{box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(236, 82, 82, 0.79);
    border:thin solid #e92d3d $mp;}
  }
  .mM_new_item_btn{
    width:100%; padding: 5px; display: flex; border: thin solid #ddd; border-radius: 5px;
    justify-content: space-around;
  }
}
.mega_menu_settings{
  .mM_item_upper.wrapper{
    cursor: move;
    label{cursor: move;}
  }
}
.mM_item_cont{
  display:flex;flex-flow:column;align-items: center; /*padding:0 10px 10px;*/padding:unset;
  .mM_item{ border: thin solid #ddd; width: 100%; padding:unset; cursor:pointer; flex:0 0 auto;
    display:flex; flex-flow:column;
    .mM_item_upper,.mM_item_lower{}
    .mM_item_upper.wrapper{flex-flow:row wrap;}
    .mM_item_upper{
      display:flex; flex-flow:row nowrap; justify-content: space-between;
      padding:5px; height: 2.5rem; align-items: center;
      label{
        margin:unset; font-weight: unset; color: #000; width: 65%; text-align: left; padding-left:5px;
        white-space: nowrap; overflow:hidden; text-overflow: ellipsis; flex: 1 0 65%; font-size:$rem * .83;
      }
      .mM_item_btn_cont{
        width:30%;
        display:flex; justify-content:flex-end;
        button{padding: unset; height: 20px; width: 25px; flex: 0 1 auto; margin-left:2px;}
      }
      .d_hr{display:block;margin: 0px auto 2px; width: 95%; height: 3px;
        flex: 0 0 100%; background-color: transparent; border: unset;}
      .d_hr.menu,.d_hr.submenu{background-color:rgba(67, 69, 136, 0.7); }
      .d_hr.submenu{border-top: 3px solid #ddd;}
    }/*upper*/
    hr{margin:unset; display:none;}
    .mM_item_lower{
      display:none; flex-flow:column; padding: 0 5px;text-align:center; margin: 5px;
      .mM_item_btn_conf{
        padding:0 5px;justify-content: space-between;display:flex; flex-flow:row nowrap;
        button{width:45%;display:flex;padding: 5px;justify-content: center;}
      }
    }/*lower*/

  }
  .mM_item.ghost{background-color:rgba(81, 144, 244, 0.69) $mp; color:white $mp;}
  .mM_item:focus{cursor:move;}
}

//select section
.mM_select_section{
  .hold{background-color:rgba(81, 144, 244, 0.69) $mp; color:white $mp;}
  .ground{position: -webkit-sticky; position: sticky; bottom: 0px;}
  .selected{background-color:#eee $mp;}
  .form_box{padding: 5px;}
  .mM_new_item_title_editor{
    padding:5px;
    display:flex;
    flex-flow:column;
    div{
      display:flex; flex-flow:row nowrap; width:100%; align-items: center;margin-bottom: 5px;
      label{width:70%;margin:unset;}
      button{width:20%; padding:5px; display: flex; justify-content: center; height: 25px;
      padding: unset $mp; border: unset;}
    }
    input{width:100%;height:2rem;}
    input.invalid:focus{box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(236, 82, 82, 0.79);
    border:thin solid #e92d3d $mp;}
  }
  .mM_select_item_cont{padding:unset;}
  .mM_select_confirm_cont{
    background-color:#fff; padding:$rem * .25; margin-top:5px;
    button{ display: flex; justify-content: center; align-items: center; padding:2px $mp; }
  }
  .mM_select_type_btns{
    display:flex; justify-content: space-around; flex-flow:row nowrap; width:100%;
    position: -webkit-sticky; position: sticky; top: 0px; border-bottom: thin solid #ddd;
background: #fff;
    button{width:25px; height:25px;display:flex; justify-content: center;align-items: center;
    padding: unset;}
  }
  .mM_item_upper{
    display:flex; flex-flow:row nowrap; justify-content: space-between;
    padding:5px; height: 25px; align-items: center;
    label{margin:unset; font-weight: unset; color: #000;width:60%;
    white-space: nowrap; overflow:hidden; text-overflow: ellipsis;}
    button, .mM_select_icon, .mM_placeholder{padding: unset; height: 20px; width: 25px; flex: 0 1 auto; margin-left:2px;}

  }/*upper*/
}

/*ellipsis with line heights*/
.clamp{
overflow: hidden;
text-overflow: ellipsis;
-webkit-line-clamp: 3;
-webkit-box-orient: vertical;
display: block;
display: -webkit-box;
line-height: 1.4;
}

.sticky-top,.sticky_top{ position: -webkit-sticky; position: sticky; top: 0px; background:#fff;}
.sticky-bot,.sticky_bot{ position: -webkit-sticky; position: sticky; bottom: 0px; background:#fff;}
.flexible{flex:1 1 auto $mp;}
.flex_grow{flex:1 0 auto $mp;}
.flex_shrink{flex:0 1 auto $mp;}

.info_space{position:relative;}
.fyinfo{
  display:flex;
  flex-flow:row nowrap;
  width:100%;
  justify-content: flex-end;
  margin-top: 10px;
  button.info_btn{
  display: flex; justify-content: center; align-items: center; flex: 0 1 auto; position:absolute;
  right:5px; top:5px; width:20px; height:20px; padding:0px $mp; border:unset $mp; margin-left: 5px;
  }
  p{ font-size: 11px; line-height: 15px; width:100%; text-align: justify;}
}
.fyinfo.open{padding: 5px;}

.megaMenu_hidden{
  width:100%; position:relative;
  .megaMenu_curtain{
    display: flex; justify-content: center; align-items: center;
    width:100%; height:8rem;
    .loader{border-width: $rem * .5; width:75px; height:75px;}
  }
}

.invisible{display:none $mp;}
.linkable{cursor:pointer $mp;}

@media only screen and (min-width:320px) and (max-width:479px){

}

@media only screen and (min-width:480px) and (max-width:767px){

}

@media only screen and (min-width:768px) and (max-width:991px)
{

}

@media only screen and (min-width:992px) and (max-width:1999px)
{

}
