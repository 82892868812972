$rem:1rem;
$huge:26.25rem;
$tall:21.25rem;
$medium:10rem;
$short:2.8rem;
$single:10%;
$full:100%;
$qtr:$rem * .25;//5px
$hlf:$rem * .5;
$fxmp:flex !important;

$mp:!important;

.tall{height:$tall;}
.medium{height:$medium;}
.short{height:$short;}
.full{height:$full;}
.huge{height:$huge;}

.canvas_cont.imalign_center{text-align: center;}

div.canvas_wrapper{
  display:flex;
  width:100% $mp;
  flex: 0 0 auto;
  /*height:100% $mp;*/
  /*margin:.5rem auto;*/
  .canvas_cont{
    display:table-cell $mp;
    /*width:unset $mp;*/
    width:100%;
    height:100%;
    .blogMod.canvas_area.portrait{flex-flow:row nowrap;}
    .blogMod.canvas_area{
      margin:0 auto;
      width:100% $mp;
      height:100% $mp;
      display:flex $mp;
      flex-flow:column nowrap;
      /*
      width:unset $mp;
      height:unset $mp;
      display:unset $mp;
      */
      .blogMod.ImgCanvas{width:100% $mp; height:auto $mp;}
      .blogMod.ImgCanvas.portrait{width:unset $mp; height:100% $mp;}
    }
  }
}


.blog-module{
  /*display: flex;  // flex right here is creating a problem with another section borders overrun*/
  justify-content: center;
  margin: 0 auto;
  .d3_range_stack{
    width:100%;
    .bM_stgs_label_cont{
      display:flex;
      width:100%;
      .d3_auto_range{
        display:flex; flex-flow:column; align-items: center;
        label{text-align:center;}
      }
    }
    .word_box{ input{max-width:90px;}}
  }
  .showTime_blog_module{
     max-width:unset;/*flexbox clashes with w3CSS? breaks the display*/
    width:100%;
    .blogMod_main{
        display:flex;justify-content: center; max-width:unset;flex-flow:row wrap;
      .blogMod_item{
        display: inline-flex;
        flex-flow: column;
        /*width:30% $mp;*/
        /*height:100% $mp;*/
        background-color:#fff;
        margin:$rem * .25;
        padding:10px;
        .read_more_cont{
          display:inline-flex; align-items:flex-end; flex: 1; width: 100%;
          .read_more{cursor:pointer; text-align: center;}
          .button {text-decoration: none;}
        }
      }
      .blogMod_head_html{
         text-shadow: none $mp;
        p{margin:0px;}
        .head_date{font-size:.1rem}
      }
      .blogMod_body_html{
        p{margin:0px;}
      }
      .blogMod_head_html.master,.blogMod_body_html.master, .master {
        p{ display: flex; align-items: center; margin: 0 0 10px $mp;}
        *{font-size:inherit $mp; line-height: inherit $mp; font-family: inherit $mp;
          color: inherit $mp; text-decoration: inherit $mp;}
      }
      button.slick-arrow {
          display: none $mp;
      }
      button{
        padding:unset $mp;
        border: none;
        display: inline-block;
        outline: 0;
        padding: 8px 16px;
        vertical-align: middle;
        overflow: hidden;
        text-decoration: none;
        color: inherit;
        background-color: inherit;
        text-align: center;
        cursor: pointer;
        white-space: nowrap;
      }
      button.w3-button:hover{background-color:unset $mp;}
    }/*blogMod_main*/
  }


    .blogMod_img_cont{
      position: relative; overflow: hidden; justify-content: center; align-items: center;
      .blogMod_img_wrap{
        margin:unset;
        .blogMod_slide_mod{
          display:flex; width:100%; height:100%; position:absolute;
          .blogMod_head_html,.blogMod_body_html.flex,.read_more_cont{display:flex;}
          .blogMod_head_html.master,.blogMod_body_html.master, .master{
            p{ display: flex; align-items: center; margin: 0 0 10px;}
            *{font-size:inherit $mp; line-height: inherit $mp; font-family: inherit $mp;
            color: inherit $mp; text-decoration: inherit $mp;}
          }
        }/*blogMod_slide_mod*/
      }
    }
  .blog_module_layer  .blogMod_blog_module{max-width: unset $mp;}
  .blogMod_main.strict{.blogMod_body_html{/*flex:1;*/}}
  .blogMod_main.layer{
    display:flex; flex-flow: row wrap;
    .blogMod_item.layer{
      /* for layered blogMod */
      .blogMod_main,.blogMod_item{display:flex;}
      position:relative; overflow:hidden;
      .blogMod_img_cont{position:relative; overflow:hidden; height:100%;
        .portrait{width:unset $mp; height:100% $mp;}
      }
      .blogMod_slide_mod.img{z-index: 10;}
      .blogMod_slide_mod.txt{z-index: 20;}
      .blogMod_slide_mod{
        display:flex; width:100%; height:100%; position:absolute;
        top:0; left:0;
        .blogMod_head_html,.blogMod_body_html.flex,.read_more_cont{display:flex;}
        .blogMod_head_html.master,.blogMod_body_html.master, .master{
          p{ display: flex; align-items: center; margin: 0 0 10px $mp;}
          *{font-size:inherit $mp; line-height: inherit $mp; font-family: inherit $mp;
          color: inherit $mp; text-decoration: inherit $mp;}
        }
      }/*blogMod_slide_mod*/
    }
    .master{
      p{ display: flex; align-items: center; margin: 0 0 10px $mp;}
      *{font-size:inherit $mp; line-height: inherit $mp; font-family: inherit $mp;
      color: inherit $mp; text-decoration: inherit $mp;}
    }/*.master*/
  }/*blogMod_main.layer*/

  .blogMod_slide_mod.hovering{
    background-color: red;
    animation-name: trios;
    animation-duration: .5s;
    animation-timing-function: ease-in;
  }

  @keyframes trios {
    from {opacity:0;}
    to {opacity:.71;}
  }

  .flex_fill{flex:1 0 auto $mp;}
  .bM_stgs {
      flex:1;
      width:100%;
      .bM_stgs_ctrls_cont{
        display:flex; flex-flow:row nowrap;
        .bM_stgs_ctrls{flex:1; border:1px solid blue; padding:1px $mp;}
        .bM_stgs_ctrls.first{margin-right:2px;}
      }
      .bM_stgs_sect_cont{max-height:$rem * 20; overflow-y:auto; margin-top:10px;border: 1px solid #ddd; position:relative;}
      .active{background-color:#17315a; color:white;}
      .bM_stgs_advanced{
        position:relative;
        .bM_stgs_option_back{position:absolute; top:5px; right:5px;padding:unset;}
        label.heading{/*margin:3px 10px;*/ margin: unset; height: 25px; padding: 5px 10px; width: 100%; z-index: 1000;}
        .option_section{
          display:flex;
          flex-flow:column nowrap;
          margin-top:5px;
          min-height: 80px;
          .adv_option_btn:first-child{border:1px solid #ccc;}
          .adv_option_btn{
            display:flex; flex-flow:row;
            justify-content: space-between;
            align-items: center;
            flex:1; padding:2px 5px;
            border:1px solid #ccc; height:30px;
            text-align:left $mp;
            border-top:unset;
            p{width:70%; margin:unset;}
            .list_icon{width:10%; display: flex; justify-content: center; align-items: center; }
          }
        }
      }
      textarea {min-height: 15rem $mp;}
      textarea[class*="_stgs_advanced_class"],
      textarea[class*="custom_class"],
      textarea .custom_class{min-height: 5rem $mp;}
  }/*_stgs*/
  .bM_stgs_sect{display:flex; flex-flow:column; width:100%;}
}
.bM_stgs_label{text-shadow: 2px 2px 2px #ccc;}
.bM_stgs_content_box{
  border: 1px solid #ddd; padding:10px 10px 15px;
  label{width:100%;}
  .measure_btns{ width:100%;margin:15px auto 5px;}

}

.bM_stgs_current_info{
  display:flex;
  flex-flow:wrap row;
  div{flex:1;}
}
.bM_stgs_custom_info,.bM_stgs_view_info{
  display:flex; flex-flow:wrap;
  .bM_stgs_size_wrapr{
    div{width:30%; display:inline-block;}
    .bM_stgs_custom_input,.bM_stgs_view_input{width:40% $mp; display:inline-block; padding: 10px;
     margin: 0px 0 10px  2%; vertical-align: top;}
  }
}
.bM_stgs_mobility_info,.bM_stgs_button_info{
  display:flex; flex-flow:wrap;
  .bM_switch_cont{
    width:100%;
    .bM_switch_label{width:unset; margin-right:7%;}
    .fs_slider{min-width: 54px;}
  }


  button{flex:1; border:1px solid blue; padding:1px $mp; margin-right:2px; padding-top:5px $mp; border-radius:0;}
  button.last{ border-radius: 0 5px 5px 0; margin-right:unset;}
  button.first{border-radius: 5px 0 0 5px; }
  button.justify.active{
    /*background-color: #17315a6b;*/
    .material-icons{color:#fff $mp;}
  }
}
.bM_stgs_bg_info{
  display:flex;
  flex-flow:row wrap;
  input{flex:1; padding:0 10px $mp; width:100%;}
}
.bM_stgs_bg_info.col{
  flex-flow:column nowrap;
  button.custom_delete, button.view_delete{background-color: rgba(232, 169, 169, 0.44); border-radius: 5px;}
}
.bM_stgs_class_info{
  display:flex;
  flex-flow:row wrap;
  input{flex:1; width:100%; padding:10px;}
}
.bM_stgs_class_info.column{
  flex-flow:column nowrap $mp;
}
.bM_custom_new_item_btn, .bM_view_new_item_btn{
  padding:unset $mp;
}
.bM_custom_head_cont,.bM_view_head_cont{
  display:flex;
  flex-flow:row nowrap $mp;
  .bM_custom_title_input_cont input, .bM_view_title_input_cont input{height:30px;}
  button{padding:unset $mp;width:30px; height:30px; display:flex;
    justify-content: center; margin-left:5px;}

}
.bM_stgs_reset_btn{width:90%; margin:10px auto; border:1px solid blue; padding:unset $mp;}
.bM_font_size,.bM_line_height{
  display:flex; flex-flow:column;

  .word_box{
    display:flex;
    flex-flow: row nowrap;
    width:50% $mp;

    input{padding:10px $mp; width:40% $mp; margin-right: 5px;}
    label{width:8%;}
  }
  .font_measure_btns{ width:100%;margin:5px auto 15px;}
}

.blog-module.nav_blog{
  .button{
    border: none;
    display: inline-block;
    outline: 0;
    padding: 8px 16px;
    vertical-align: middle;
    overflow: hidden;
    text-decoration: none;
    color: inherit;
    background-color: inherit;
    text-align: center;
    cursor: pointer;
    white-space: nowrap;
  }
  .showTime_blog_module{width:100%;}
  display:flex; overflow:hidden;
  .col_wr{flex-flow:column wrap;}
  .col_no{flex-flow:column nowrap;}
  .row_wr{flex-flow:row wrap;}
  .row_no{flex-flow:row nowrap;}
  button{flex:0 0 auto;}
  .showTime_blog_module{
    display:block; overflow:hidden; margin:unset;
    width:100%;
    .blogMod_main{
      display:block;
      justify-content: flex-start; position:relative;
      .bM_slider {width:100% $mp;}
      .bM_slider.part{margin-bottom:unset $mp;}
        .slick-list{overflow:hidden;}
        .slick-dots{bottom:-30px;}
        .blogMod_item_wrap{display:flex;justify-content:center;}
      .blogMod_item{flex: 0 0 auto $mp; margin:unset; }
    }
    .blogMod_main.v_nav{display:flex $mp; align-items: center;}
  }
}
.blog-module.nav_blog.h_nav{flex-flow:row nowrap; justify-content: center;}
.blog-module.nav_blog.v_nav{flex-flow:column nowrap; align-items: center;}

.clamp{
overflow: hidden;
text-overflow: ellipsis;
-webkit-line-clamp: 3;
-webkit-box-orient: vertical;
display: block;
display: -webkit-box;
line-height: 1.4;
}

.sticky-top,.sticky_top{ position: -webkit-sticky; position: sticky; top: 0px; background:#fff;}
.sticky-bot,.sticky_bot{ position: -webkit-sticky; position: sticky; bottom: 0px; background:#fff;}

.info_space{position:relative;}

.fyinfo{
  display:flex;
  flex-flow:row nowrap;
  width:100%;
  justify-content: flex-end;
  margin-top: 10px;
  button.info_btn{
  display: flex; justify-content: center; align-items: center; flex: 0 1 auto; position:absolute;
  right:5px; top:5px; width:20px; height:20px; padding:0px $mp; border:unset $mp; margin-left: 5px;
  }
  p{ font-size: 11px; line-height: 15px; width:100%; text-align: justify;}
}
.fyinfo.open{padding: 5px;}

.blogMod_hidden{
  width:100%; position:relative;
  .blogMod_curtain{
    display: flex; justify-content: center; align-items: center;
    width:100%; height:8rem;
    .loader{border-width: $rem * .5; width:75px; height:75px;}
  }
}

.invisible{display:none $mp;}
.linkable{cursor:pointer $mp;}

@media only screen and (min-width:320px) and (max-width:479px){

}

@media only screen and (min-width:480px) and (max-width:767px){

}

@media only screen and (min-width:768px) and (max-width:991px)
{

}

@media only screen and (min-width:992px) and (max-width:1999px)
{

}
