.fit-contain{
  object-fit: contain;
}
.fit-cover{
  object-fit: cover;
}

.grid{
  display:grid;
}

.asset_snap{
  align-self: center;
  height: 100% $mp;
  width: 100% $mp;
}
